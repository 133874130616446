@import '../../../node_modules/tiny-slider/src/tiny-slider';
.key-links {
  overflow: hidden;
  &__slider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      content: "";
      background-color: #1f214d;
      opacity: 0.6;
      z-index: 1;
    }
    .slide {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}